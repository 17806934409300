import { add, differenceInMinutes, intervalToDuration } from "date-fns";
export function getRecruitDeadline(targetDate) {
    let now = new Date(), diff = 10 * Math.round(differenceInMinutes(targetDate, now) / 20);
    if (diff < 30) return {
        minutes: 30
    };
    let deadline = add(now, {
        minutes: diff
    });
    return intervalToDuration({
        start: now,
        end: deadline
    });
}
